import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card, Alert } from "react-bootstrap";
import { ORDER_UPDATE_RESET } from "../../constants/pickupOrderConstant";
import "./../Form.scss";
import imageCompression from "browser-image-compression";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { ClipLoader } from "react-spinners";

import { createCustomerCRMAction } from "../../actions/sellerInfoAction";
import { PropagateLoader } from "react-spinners";
import CustomerInfoInput from "./CustomerInfoInput";
import { MDBBtn } from "mdb-react-ui-kit";
import { pickupOrederDetail } from "../../actions/pickupOrderAction";
import CustomerDocumentInput from "./CustomerDocumentInput";

// Define your validation schema
const schema = yup.object().shape({
  user: yup.string().required("User is required"),
  phone_number: yup.string().required("Phone Number is required"),
  email: yup.string().required("Email is required"),
  dob: yup.string().required("Date of Birth is required"),
  product: yup.string().required("Product is required"),
  brand: yup.string().required("Brand is required"),
  final_price: yup
    .number()
    .typeError("Device Cost must be a number")
    .required("Device Cost is required"),
  ram: yup.string().required("ram is required"),
  storage: yup.string().required("storage is required"),
  warrenty: yup
    .object()
    .shape({
      value: yup.string().required("Warranty is required"),
    })
    .nullable()
    .required("Warranty is required"),

  payment: yup
    .object()
    .shape({
      value: yup.string().required("Payment Model is required"),
    })
    .nullable()
    .required("Payment Mode is required"),
  upi: yup.string().required("UPI ID is required"),
  imei: yup.string().required("imei is required"),
  device_color: yup.string().required("device color is required"),
  pick_up_location: yup.string().required("Address is required"),
  aadhaar_front_image: yup.mixed().required("Front image is required"),
  aadhaar_back_image: yup.mixed().required("Back image is required"),
  digital_signature: yup.string().required("Signature is required"),
  // document_0: yup
  //   .object()
  //   .shape({
  //     label: yup.string().required("Document type is required"),
  //   })
  //   .nullable()
  //   .required("Document type is required"),
  // document_image0: yup.mixed().required("Document image is required"),
  // document_1: yup
  //   .object()
  //   .shape({
  //     label: yup.string().required("Document type is required"),
  //   })
  //   .nullable()
  //   .required("Document type is required"),
  // document_image1: yup.mixed().required("Document image is required"),
});

export default function CreateCustomerCRM() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createSellerInfo = useSelector((state) => state.updateSellerInfo);
  const { loading: sellerLoading, success: createSellerSuccess } =
    createSellerInfo;

  const orderDetail = useSelector((state) => state.orderDetail);
  const { order_detail, loading: isLoading, error: oError } = orderDetail;
  const orderUpdate = useSelector((state) => state.orderUpdate);
  const { order_update } = orderUpdate;
  const { id } = useParams();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      user: order_detail?.user,
      final_price: order_detail?.final_price,
      pick_up_location: order_detail?.pick_up_location,
      phone_number: order_detail?.phone_number,
      product: order_detail?.product,
      brand: order_detail?.brand_name,
      email: order_detail?.email,
    },
  });

  useEffect(() => {
    if (createSellerSuccess) {
      navigate("/crm");
    }
  }, [createSellerSuccess, navigate]);

  useEffect(() => {
    if (order_detail) {
      setValue("user", order_detail?.user);
      setValue("final_price", order_detail.final_price);
      setValue("pick_up_location", order_detail.pick_up_location);
      setValue("phone_number", order_detail?.phone_number);
      setValue("brand", order_detail?.brand_name);
      setValue("product", order_detail?.product);
      setValue("email", order_detail?.email);
    }
  }, [order_detail, setValue]);

  useEffect(() => {
    if (order_update) {
      setTimeout(() => {
        navigate("/orders", { replace: true });
      }, 1000);
    }
    dispatch({ type: ORDER_UPDATE_RESET });
  }, [order_update, navigate, dispatch]);

  useEffect(() => {
    dispatch(pickupOrederDetail(id));
  }, [id, dispatch]);

  const orderSubmitForm = async (data) => {
    const postData = {
      order_id: Number(id),
      final_price: data["final_price"],
      location: data["pick_up_location"],
      imei: data["imei"],
      user: data["user"],
      email: data["email"],
      phone_number: data["phone_number"],
      ram: data["ram"],
      storage: data["storage"],
      brand: data["brand"],
      product: data["product"],
      dob: data["dob"],
      warrenty: data["warrenty"],
      upi: data["upi"],
      payment: data["payment"],
      device_color: data["device_color"],
      documents: [],
      aadhaar: {},
      signature: data.digital_signature,
    };

    const compressImage = async (file) => {
      if (file.size > 500 * 1024) {
        // If the file is larger than 500 KB
        const options = {
          maxSizeMB: 0.4, // This sets the maximum size to around 500 KB
          maxWidthOrHeight: file.width, // Maintain original dimensions
          useWebWorker: true,
        };
        try {
          const compressedFile = await imageCompression(file, options);
          return compressedFile;
        } catch (error) {
          console.error("Error compressing image:", error);
          return file; // If compression fails, return the original file
        }
      } else {
        return file; // If the file is under 500 KB, return it uncompressed
      }
    };

    // const fileToBase64 = (file) => {
    //   try {
    //     return new Promise((resolve, reject) => {
    //       const reader = new FileReader();
    //       reader.onloadend = () => resolve(reader.result);
    //       reader.onerror = reject;
    //       reader.readAsDataURL(file);
    //     });
    //   } catch (error) {
    //     console.error("Error compressing the image:", error);
    //   }
    // };

    const validateImage = (file) => {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

      if (!allowedTypes.includes(file.type)) {
        alert("Invalid file type. Please upload a JPEG or PNG image.");
        return false;
      }

      if (file.size > maxSizeInBytes) {
        alert("File size exceeds 10MB. Please upload a smaller image.");
        return false;
      }

      return true;
    };

    const fileToBase64 = async (file) => {
      try {
        // Check if the file size exceeds 500 KB and compress if necessary
        if (file.size > 500 * 1024) {
          const options = {
            maxSizeMB: 0.3, // Set the maximum size to around 400 KB
            maxWidthOrHeight: file.width, // Maintain original dimensions
            useWebWorker: true,
          };

          try {
            file = await imageCompression(file, options);
          } catch (error) {
            console.error("Error compressing image:", error);
            // Proceed with the original file if compression fails
          }
        }

        // Convert the file (compressed or original) to base64
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      } catch (error) {
        console.error("Error converting the image to base64:", error);
        throw error;
      }
    };

    const documentPromises = [];

    for (const key in data) {
      if (key.startsWith("document_")) {
        const documentIndex = parseInt(key.split("_")[1]);
        const documentValue = data[key];
        const documentImageKey = `document_image${documentIndex}`;

        const documentImageValue = data[documentImageKey];

        if (documentValue && documentImageValue) {
          if (documentImageValue[0] instanceof File) {
            const file = documentImageValue[0];

            if (validateImage(file)) {
              const promise = fileToBase64(file).then((base64Image) => {
                return {
                  document_type: documentValue
                    ? documentValue.label
                    : undefined,
                  document_image: base64Image || "",
                };
              });
              documentPromises.push(promise);
            }
            else {
              return
            }
          } else {
            console.error(
              `document_image${documentIndex} is not a File instance:`,
              documentImageValue
            );
          }
        }
      } else {
        if (data[key]) {
          postData[key] = data[key];
        }
      }
    }

    postData.documents = await Promise.all(documentPromises);

    // Process Aadhaar document
    if (data.aadhaar_front_image[0] && data.aadhaar_back_image[0]) {
      const aadhaarFrontImage = data.aadhaar_front_image[0];
      const aadhaarBackImage = data.aadhaar_back_image[0];

      if (validateImage(aadhaarFrontImage) && validateImage(aadhaarBackImage)) {
        const aadhaarFrontImageBase64 = await fileToBase64(
          data.aadhaar_front_image[0]
        );
        const aadhaarBackImageBase64 = await fileToBase64(
          data.aadhaar_back_image[0]
        );

        postData.aadhaar = {
          front_image: aadhaarFrontImageBase64,
          back_image: aadhaarBackImageBase64,
        };
      } else {
        return;
      }
    }
    dispatch(createCustomerCRMAction(postData));
  };

  return (
    <>
      <div className="order-form">
        {isLoading && (
          <div className="loading-container">
            <PropagateLoader color="#36d7b7" />
          </div>
        )}
        {oError && (
          <div className="error-container">
            <Alert variant="danger" style={{ backgroundColor: "#ffffff" }}>
              <Alert.Heading>Error</Alert.Heading>
              <p>{oError}</p>
            </Alert>
          </div>
        )}
        {order_detail && (
          <>
            <Card>
              <form onSubmit={handleSubmit(orderSubmitForm)}>
                <div className="custom-border">
                  <CustomerInfoInput
                    order_detail={order_detail}
                    control={control}
                    errors={errors}
                  />
                  <CustomerDocumentInput
                    control={control}
                    seller_info_list={order_detail}
                    errors={errors}
                  />
                </div>

                <div className="update-btton-container">
                  <MDBBtn
                    // className="mt-4"
                    className={`${sellerLoading ? "dimmed-button" : ""}`}
                    type="submit"
                    style={{ height: "2.5rem", width: "6rem" }}
                    block
                  >
                    {sellerLoading && <ClipLoader color="#36d7b7" size={20} />}
                    Update
                  </MDBBtn>
                </div>
              </form>
            </Card>
          </>
        )}
      </div>
    </>
  );
}
