import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { admin_login } from "../../actions/adminUserAction";
import "./Admin.scss";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const admin_user = useSelector((state) => state.adminLogin);
  const {
    admin,
    error: adminError,
    success: loginSuccess,
    loading: adminLoading,
  } = admin_user;

  const [credentials, setCredentials] = useState({
    phone_number: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    phone_number: "",
    password: "",
  });

  useEffect(() => {
    if (adminError) {
      toast.error(adminError);
    }
    if (admin) {
      navigate(`/`);
    }
  }, [admin, navigate, adminError]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the corresponding error message
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const { password, phone_number } = credentials;
    const newErrors = {};

    if (!phone_number) {
      newErrors.phone_number = "Please select a phone number";
      isValid = false;
    }

    if (!password) {
      newErrors.password = "Please select password";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const submitForm = (event) => {
    event.preventDefault();
    if (validateForm()) {
      dispatch(admin_login(credentials.phone_number, credentials.password));
    }
  };

  return (
    <>
      <MDBContainer fluid>
        {!admin && (
          <div className="adminLogin"
              style={{
                backgroundImage:
                  "url(https://buybackart-s3-media-storage.s3.ap-south-1.amazonaws.com/static/extras/35055371_8270289.webp)",
                  height: "100vh",
                  width: "100vw",
                  backgroundSize: "cover",
                  marginLeft: "-12px",
              }}>
            

            <MDBCard>
              <MDBCardBody className="card-body-container">
                <h2>Log In</h2>
                <Form onSubmit={submitForm}>
                  <div className="input-container">
                    <MDBInput
                      className={classnames("react-select", {
                        "is-invalid": errors.phone_number, // Add 'is-invalid' class when there's an error
                      })}
                      wrapperClass="mb-4"
                      name="phone_number"
                      label="Phone Number"
                      value={credentials.phone_number}
                      type="text"
                      onChange={handleInputChange}
                    />
                    <MDBInput
                      className={classnames("react-select", {
                        "is-invalid": errors.password, // Add 'is-invalid' class when there's an error
                      })}
                      name="password"
                      label="Password"
                      type="password"
                      value={credentials.password}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="checkbox-container">
                    {/* <MDBCheckbox
                      name="flexCheck"
                      value=""
                      id="flexCheckDefault"
                      label="Remember me"
                    /> */}
                    <Link to={`/crm/admin-forgot-password-email`}>
                      <p>Forgot password?</p>
                    </Link>
                  </div>

                  <div className="btn-container">
                    <MDBBtn
                      className={`${adminLoading ? "dimmed-button" : ""}`}
                    >
                      {adminLoading && <ClipLoader color="#36d7b7" size={20} />}
                      <span>Login</span>
                    </MDBBtn>
                  </div>
                </Form>
              </MDBCardBody>
            </MDBCard>
          </div>
        )}
      </MDBContainer>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default AdminLogin;
